import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
//import Twitter from './Twitter';
//import Facebook from './Facebook';

const SEO = ({
  title = null,
  description = null,
  keywords = null,
  pathname = null,
  article = false,
  showSchema = false,
  breadcrumbs = null,
  rating = null,
  gameTitle = null,
  socialImage = "/logo.png",
}) => (
  <StaticQuery
    query={graphql`
      query SEOFaqQuery {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            twitterUsername: twitterUsername
            facebookAppID: facebookAppID
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultDescription,
          siteUrl,
          twitterUsername,
          facebookAppID,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${socialImage}`,
        siteUrl: `${siteUrl}${pathname || "/"}`,
      }

      //console.log('Seo rendering...');

      const breadcrumbsArray = breadcrumbs ? [] : null
      let breadcrumbPosition = 1
      if (breadcrumbs) {
        breadcrumbs.forEach(function (element) {
          let url = element.slug
            ? process.env.URL + "/" + element.slug + "/"
            : process.env.URL + "/"
          let breadcrumbRepresentation = {
            "@type": "ListItem",
            position: breadcrumbPosition,
            name: element.title,
            item: url,
          }
          breadcrumbsArray.push(JSON.stringify(breadcrumbRepresentation))
          breadcrumbPosition++
        })
      }

      return (
        <Helmet title={seo.title} defer={false}>
          <html key="app-lang" lang="th" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="description" content={seo.description} />
          <meta
            name="google-site-verification"
            content="HcSuMRFhP3oMJOAjZAyp3rjoGoCz0xDPckdi8KCIF8w"
          />
          <meta
            key="app-viewport"
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <meta name="robots" content="all,noodp" />
          <meta name="googlebots" content="all,noodp" />
          <meta name="revisit-after" content="1 days" />
          <link rel="preconnect" href="https://stats.g.doubleclick.net" />
          <link rel="preconnect" href="https://www.google.com" />
          <link rel="canonical" href={seo.siteUrl} />
          <meta name="image" content={seo.image} />
          {keywords ? <meta name="keywords" content={keywords} /> : null}

          {/* OpenGraph tags */}
          <meta property="og:url" content={seo.siteUrl} />
          {article ? <meta property="og:type" content="article" /> : null}
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
          {facebookAppID && (
            <meta property="fb:app_id" content={facebookAppID} />
          )}

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          {twitterUsername && (
            <meta name="twitter:creator" content={twitterUsername} />
          )}
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />

          {showSchema && (
            <script type="application/ld+json">
              {`
              {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "@id":"#website",
                "url":"${siteUrl}${pathname || "/"}",
                "name": "${seo.title}",
                "potentialAction": {"@type":"SearchAction","target":"${
                  seo.siteUrl
                }?s={search_term_string}","query-input": "required name=search_term_string"}
              }
            `}
            </script>
          )}
          {breadcrumbsArray && (
            <script type="application/ld+json">
              {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${breadcrumbsArray}]
            }
            `}
            </script>
          )}
          {rating && gameTitle && (
            <script type="application/ld+json">
              {`
            {
              "@context": "http://schema.org",
              "@type": "Brand",
              "name": "${gameTitle}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "bestRating": "5",
                "ratingCount": "74",
                "ratingValue": "${rating}"
              }
            }
            `}
            </script>
          )}
          {
            <script type="application/ld+json">
              {`
                  {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [{
                    "@type": "Question",
                    "name": "1. เกมคาสิโนออนไลน์คืออะไร?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "คาสิโนออนไลน์ คือรูปแบบเกมคาสิโนที่ปรับเปลี่ยนมาวางเดิมพันผ่านระบบอินเตอร์เน็ต เอื้อให้คุณสามารถเข้าถึงการทำกำไรได้ทุกที่ทุกเวลาตลอด 24 ชั่วโมง โดยไม่จำเป็นต้องเดินทางไปไหน อีกทั้งยังสัมผัสประสบการณ์วางเดิมพันได้ไม่ต่างจากเดินทางไปที่คาสิโนด้วยตัวเอง ช่วยประหยัดค่าใช้จ่ายสิ้นเปลืองได้บานเบอะ ที่สำคัญยังเสนออัตราการจ่ายเงินที่สูงกว่าคาสิโน เช่นเดียวกับโบนัสที่มีให้รับได้ตลอดทั้งปี"
                  }
                  }, {
                    "@type": "Question",
                    "name": "2. เกมคาสิโนออนไลน์แบ่งออกเป็นกี่ประเภท?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "ตามหลักการแล้วเกมคาสิโนออนไลน์จะแบ่งออกเป็น 3 ประเภทใหญ่ๆ คือคาสิโนสด คาสิโนอิเล็กทรอนิกส์ และเดิมพันกีฬา ซึ่งแต่ละรูปแบบก็จะมีจุดเด่นแตกต่างกันออกไป ยกตัวอย่างเช่น คาสิโนสดที่นำเสนอรูปแบบวางเดิมพันแบบเรียลไทม์ไม่ต่างจากการเดินทางไปเล่นที่คาสิโนด้วยตัวเอง คาสิโนอิเล็กทรอนิกส์ที่นำเสนออัตราการจ่ายเงินที่สูง และ เดิมพันกีฬาที่เอื้อให้วางเดิมพันและคว้าเงินรางวัลจากเกมการแข่งขันได้หลากหลายรูปแบบ"
                  }
                  }, {
                    "@type": "Question",
                    "name": "3. HolidayPalace.net มีบริการอะไรบ้าง?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "holidaypalace.net จัดเป็นเว็บคาสิโนออนไลน์ครบวงจร แม้จะยังไม่มีเดิมพันกีฬาออนไลน์ให้ได้ทำกำไร แต่หากถึงเกมเสี่ยงโชครูปแบบอื่นๆ คุณสามารถเข้าถึงการวางเดิมพันคาสิโนสดอย่างแบล็คแจ็ค, รูเล็ต, ป๊อกเด้ง, บาคาร่า หรือคาสิโนอิเล็กทรอนิกส์อย่างแบล็คแจ็ค รูเล็ต สล็อตออนไลน์ และเกมเสี่ยงโชคอื่นๆ อีกมากมายที่คุณต้องการ."
                  }
                  }, {
                    "@type": "Question",
                    "name": "4. ใครที่สามารถใช้บริการคาสิโนออนไลน์ได้บ้าง?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "HolidayPalace.net เอื้อให้พี่น้องนักเสี่ยงโชคชาวไทยทุกเพศทุกวัยสามารถเข้าถึงบริการคาสิโนออนไลน์ของเราได้ตลอด 24 ชั่วโมง เพียงแค่มีคอมพิวเตอร์หรือโทรศัพท์มือถือที่เชื่อมต่ออินเตอร์เน็ตสักเครื่อง จากนั้นติดต่อมาหาฝ่ายบริการลูกค้าของเราเพื่อขอรับยูสเซอร์ เพียงเท่านี้คุณก็สามารถล็อกอินเข้าสู่ระบบไปทำกำไรจากเกมเสี่ยงโชคที่ชื่นชอบได้ทุกเมื่อที่ต้องการ การสมัครทั้งหมดฟรีไม่มีค่าใช้จ่ายแต่อย่างใด"
                  }
                  }, {
                    "@type": "Question",
                    "name": "5. ถ้าสนใจเล่นคาสิโนออนไลน์ ต้องทำอย่างไรบ้าง?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "ติดต่อเข้ามาฝ่ายบริการลูกค้าของ HolidayPalace.net ได้ทุกช่องทางที่ระบุไว้ จากนั้นแจ้งเจ้าหน้าที่คอลเซนเตอร์ว่าต้องการสมัครสมาชิกใหม่ บอกรายละเอียดส่วนตัวเพื่อประกอบการเปิดยูสเซอร์เล็กน้อย ใช้เวลาไม่เกิน 5 นาที คุณก็จะได้รับ Username และ Password ไปล็อกอินเข้าสู่ระบบ พร้อมทั้งฝากเงินก้อนแรกเพื่อเปลี่ยนเป็นเครดิตไว้ปั่นทำกำไรและรับโบนัสจากเกมคาสิโนออนไลน์ที่ชื่นชอบ"
                  }
                  }, {
                    "@type": "Question",
                    "name": "6. ถ้าสนใจเล่นคาสิโนออนไลน์ ต้องทำอย่างไรบ้าง?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "HolidayPalace.net เป็นเว็บคาสิโนออนไลน์ ที่มีใบอนุญาตเปิดให้บริการอย่างถูกต้องตามกฎหมาย และผ่านการควบคุมดูแลขององค์กรคาสิโนระดับโลก เป็นหนึ่งในธุรกิจของเครือ Holiday Palace Casino & Hotel ยักษ์ใหญ่แห่งปอยเปต กัมพูชา คุณจึงสามารถมั่นใจเรื่องของความปลอดภัยได้เต็ม 100 เปอร์เซ็นต์ พร้อมปราศจากปัญหาการโกงการหลอกลวง ช่วยให้คุณทำกำไรได้อย่างไร้กังวลทุกเมื่อที่ต้องการ"
                  }
                  }, {
                    "@type": "Question",
                    "name": "7. สเปคขั้นต่ำของเครื่องสำหรับเล่นคาสิโนออนไลน์?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text":"ภายใต้ฉากหน้าที่สวยงามสดใส เชื่อหรือไม่ว่าคาสิโนออนไลน์เอื้อให้ผู้คนทั่วโลกสามารถเข้าถึงการวางเดิมพันได้อย่างง่ายดาย เพียงแค่มีสเปคขั้นต่ำสำหรับคอมพิวเตอร์และแล็ปท็อปดังนี้ Windows 95/98/NT/2000/XP Pentium III 800 MHz processor or greater 128 MB of system memory available 32 MB of video memory 3800 x 600 video resolution (16 bit color) 500 MB of available hard drive space"
                  }
                  }, {
                    "@type": "Question",
                    "name": "8. ความเร็วของอินเทอร์เน็ตเท่าไหร่ถึงเล่นได้ดี?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "ความเร็วเฉลี่ยของอินเทอร์เน็ตทั่วโลกอยู่ที่ประมาณ 70 Mbps ขณะที่ 100 Mbps เป็นความเร็วยอดนิยมในประเทศส่วนใหญ่ ซึ่งความเร็วดังกล่าวเพียงพอในการท่องโลกอินเตอร์เน็ตได้อย่างราบรื่น พร้อมเข้าถึงความความละเอียด 4K สูงสุด (UHD) ได้อย่างไร้กังวล แต่สำหรับการเล่นคาสิโนออนไลน์ ทราบหรือไม่ว่าคุณต้องการความเร็วขั้นต่ำเพียงแค่ 56 Kbps เท่านั้นเอง"
                  }
                  }, {
                    "@type": "Question",
                    "name": "9. ความเร็วของอินเทอร์เน็ตเท่าไหร่ถึงเล่นได้ดี?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "คุณสามารถเข้าถึงเกมคาสิโนออนไลน์ได้อย่างรวดเร็วและสะดวกสบายผ่านเว็บเบราเซอร์ทั่วไป อาทิเช่น Google Chrome หรือ Safari ซึ่งเอื้อให้ทำกำไรได้อย่างไหลลื่นไม่แพ้การดาวน์โหลดโปรแกรมติดตั้งลงบนอุปกรณ์ แต่หากคุณต้องการความสะดวกสบายและปลอดภัยเพิ่มมากยิ่งขึ้น คุณสามารถดาวน์โหลดโปรแกรมติดตั้งลงบนอุปกรณ์ได้ฟรี ใช้เวลาติดตั้งไม่เกิน 3 นาที อีกทั้งยังกินพื้นที่แค่ไม่กี่ mb เท่านั้นเอง"
                  }
                  }, {
                    "@type": "Question",
                    "name": "10. ขั้นตอนการฝาก-ถอนเงิน ใช้ระยะเวลาเท่าไร?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "เนื่องจากการรองรับธนาคารชื่อดังทั่วโลกอันรวมไปถึงธนาคารชื่อดังกลายเจ้าในไทย ทำให้ HolidayPalace.net ต้องใช้เวลาตรวจสอบการทำธุรกรรมฝากถอนเงินเพิ่มขึ้นนิดหน่อย โดยทั่วไปแล้วการฝากเงินของคุณจะถูกปรับเปลี่ยนเป็นเครดิตวางเดิมพันภายใน 30 นาที ส่วนการถอนเงินจะถูกโอนเข้าสู่บัญชีธนาคารของคุณภายในเวลา 15 นาที แต่คุณสามารถช่วยให้การทำธุรกรรมรวดเร็วขึ้นได้ ด้วยการฝากถอนเงินแบบมีเศษ เช่น 1001 บาท เป็นต้น"
                  }
                  }, {
                    "@type": "Question",
                    "name": "11. ในการฝาก-ถอนเงินมีข้อจำกัดอะไรไหม?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "เรื่องเดียวที่คุณควรให้ความสำคัญในการทำธุรกรรมฝากถอนเงิน คือ หมายเลขบัญชีธนาคารที่ทำการฝากเงินเข้ามา ต้องเป็นหมายเลขเดียวกับที่คุณได้แจ้งไว้ตอนสมัครสมาชิก และต้องเป็นชื่อนามสกุลของคุณด้วย เพื่อป้องกันการแอบอ้างจากบุคคลที่สาม เช่นเดียวกับการถอนเงินที่ต้องเป็นหมายเลขและชื่อบัญชีเดียวกับที่ได้ลงทะเบียนไว้ตอนเปิดยูสเซอร์"
                  }
                  }, {
                    "@type": "Question",
                    "name": "12. วงเงินขั้นต่ำ-สูงสุดในการฝากถอนคือเท่าไหร่?",
                    "acceptedAnswer": {
                    "@type": "Answer",
                    "text":"HolidayPalace.net เป็นเว็บคาสิโนออนไลน์ที่เอื้อให้พี่น้องนักเสี่ยงโชคชาวไทยทุกเพศทุกวัยสามารถเข้าถึงได้ง่าย นั่นจึงไม่ใช่เรื่องน่าแปลกใจที่พวกเขาจะกำหนดวงเงินขั้นต่ำในการสมัครครั้งแรกเพียง 100 บาท แล้วหลังจากนั้นแม้ว่าคุณจะมีเงิน 50 บาท ก็สามารถฝากเข้ามาเสี่ยงโชคกับเกมวางเดิมพันที่ชื่นชอบได้ แต่เงื่อนไขในการถอนเงินคือจะสามารถถอนได้เมื่อถอนขั้นต่ำ 1,000 บาท และสูงสุดไม่เกิน 200,000 บาทต่อวัน"}
                  }]
                  }
              `}
            </script>
          }
        </Helmet>
      )
    }}
  />
)

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  showSchema: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  rating: PropTypes.number,
  gameTitle: PropTypes.string,
  socialImage: PropTypes.string,
}

export default SEO
